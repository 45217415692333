import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'
import Loader from '../components/Loader'

import {getFloodReportPerMunicipality} from '../actions/reportActions'

export default function SocEcoAffTableFloodMun(props) {

  const dispatch = useDispatch()

  useEffect(()=>{
    
    if(props.municipalId){
       
        dispatch(getFloodReportPerMunicipality())
    }
    
  },[props.municipalId,dispatch])
  
    const floodReportR = useSelector(state => state.floodReportPerMunicipalityReducer)
    const {floodReportPerMunicipality,success:successfloodReportPerMunicipality} = floodReportR

    const municipalagg = useSelector(state => state.municipalAggReducers)
    const {municipalsagg,success:successmunAgg,loading:loadingMunAgg} = municipalagg

    const floodMunReport = Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality.filter((feature) => feature.psgccode === props.municipalId)
    
    const municipalsaggs = municipalsagg.find((feature) => feature.psgccode === props.municipalId)
   
    const tableRef = useRef(null);

    const exportToCSV = () => {
      if (!tableRef.current) return;
      
      const title = [ municipalsaggs.munname + ' Flood Susciptibility ']; 
      const headerRow = Array.from(tableRef.current.querySelectorAll('thead tr th')).map(cell => cell.innerText);
      const rows = Array.from(tableRef.current.querySelectorAll('tbody tr'));
      const data = rows.map(row => Array.from(row.children).map(cell => {
          let cellText = cell.innerText;
          // Wrap in quotes if the text contains a comma or a quote
          if (cellText.includes(',') || cellText.includes(' ')) {
              cellText = `"${cellText.replace(/"/g, ' ')}"`;
          }
          return cellText;
      }));
  
      const csvArray = [title,headerRow, ...data];
  
      // Convert CSV data array to a string with BOM character
      const csv = '\uFEFF' + csvArray.map(row => row.join(',')).join('\n');
  
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'exported_data.csv';
      link.click();
  };
    return (
   

          <div className="table-responsive">
              <span className="txt-color-purple pull-right"> <a href="#" onClick={exportToCSV}><i className="fa fa-download"></i> Export CSV</a></span>     
             
              <table  className="table table-bordered" ref={tableRef}>
                      <thead>
                        <tr>
                          <th>Classification</th>
                          <th>Low</th>
                          <th>Moderate</th>
                          <th>High</th>
                          <th>Very High</th>
                          <th>Safe</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>                      
                          <td>Population</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.person).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.person).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.person).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.person).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.person - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.person || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.person).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Households</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.households).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.households).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.households).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.households).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.households - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.households || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.households).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Families</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.families).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.families).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.families).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.families).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.families - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.families || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ?  Number(municipalsaggs.families).toLocaleString() : null}</td>
                        </tr> 
                        <tr>
                          <td>Male</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.male).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.male).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.male).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.male).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.male - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.male || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ?  Number(municipalsaggs.male).toLocaleString() : null}</td>
                        </tr> 
                        <tr>
                          <td>Female</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.female).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.female - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ?  Number(municipalsaggs.female).toLocaleString() : null}</td>
                        </tr>                     
                        <tr>
                          <td>Elderly</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.elderly_male + flood.elderly_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.elderly_male + flood.elderly_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.elderly_male + flood.elderly_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.elderly_male + flood.elderly_female).toLocaleString() : null})}</td>
                          <td>{Number((municipalsaggs.elderly) - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.elderly_male || entry.elderly_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.elderly).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Children</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.children_male + flood.children_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.children_male + flood.children_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.children_male + flood.children_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.children_male + flood.children_female).toLocaleString() : null})}</td>
                          <td>{Number((municipalsaggs.children) - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.children_male || entry.children_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.children).toLocaleString(): null}</td>
                        </tr>
                        <tr>
                          <td>Adult</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.adult_male + flood.adult_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.adult_male + flood.adult_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.adult_male + flood.adult_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.adult_male + flood.adult_female).toLocaleString() : null})}</td>
                          <td>{Number((municipalsaggs.adult) - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.adult_male || entry.adult_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.adult).toLocaleString(): null}</td>
                        </tr>    
                        <tr>
                          <td>Infant</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.infant_male + flood.infant_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.infant_male + flood.infant_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.infant_male + flood.infant_female).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.infant_male + flood.infant_female).toLocaleString() : null})}</td>
                          <td>{Number((municipalsaggs.infant) - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.infant_male || entry.infant_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.infant).toLocaleString(): null}</td>
                        </tr>
                        <tr>
                          <td>Person With Disabilities</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.pwd).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.pwd).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.pwd).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.pwd).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.pwd - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.pwd || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ? Number(municipalsaggs.pwd).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Indigenous People</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.ip).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.ip).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.ip).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.ip).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.ip - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.ip || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.ip).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Malnourished</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.malnurished).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.malnurished).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.malnurished).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.malnurished).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.malnurished - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.malnurished || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ? Number(municipalsaggs.malnurished).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Illness</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_with_illness - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hh_with_illness || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_with_illness).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Person With Philhealth</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.philhealth).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.philhealth).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.philhealth).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.philhealth).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.philhealth - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.philhealth || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.philhealth).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Person With Financial Assistance</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.sss_gsis).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.sss_gsis).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.sss_gsis).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.sss_gsis).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.sss_gsis - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.sss_gsis || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.sss_gsis).toLocaleString() : null}</td>
                        </tr>                      
                        <tr>
                          <td>HH With Paticipation in any Drill</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_access_drill - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hh_access_drill || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_access_drill).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Student</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hd_attending_school - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hd_attending_school || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hd_attending_school).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Potable Water</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.potable_water).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.potable_water).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.potable_water).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.potable_water).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.potable_water - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.potable_water || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.potable_water).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Electricity</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_access_electricity - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hh_access_electricity || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_access_electricity).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Internet</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_access_internet - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hh_access_internet || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_access_internet).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Construct Before 1972</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hh_1972).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hh_1972).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hh_1972).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hh_1972).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_1972 - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hh_1972 || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_1972).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Construct After 1972</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_1972_onwards - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hh_1972_onwards || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_1972_onwards).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Susceptibility</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hh_susc_flood).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hh_susc_flood).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hh_susc_flood).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hh_susc_flood).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_house - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hh_susc_flood || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_house).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Susceptibility Estimated Cost</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "LF" ? Number(flood.hh_susc_flood_cost).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "MF" ? Number(flood.hh_susc_flood_cost).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "HF" ? Number(flood.hh_susc_flood_cost).toLocaleString() : null})}</td>
                          <td>{floodMunReport && floodMunReport.map((flood) =>{return flood.flood_id === "VHF"? Number(flood.hh_susc_flood_cost).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_house_cost - (Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.hh_house_cost || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_house_cost).toLocaleString() : null}</td>
                        </tr>
                                   
                      </tbody>
             
              </table>
              
          </div>
        
    )
}
