import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'
import {floodWallRoofMaterial} from '../actions/purokActions'
import {puroklistBarangay} from '../actions/barangayActions'
import {roofMaterials,wallMaterials} from '../actions/libraryActions'




export default function WallRoofBrgyTable(props) {
    const dispatch = useDispatch()
    const [floodInfo,setFloodInfo] = useState(null)

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo

    const roofinfo = useSelector(state => state.roofReducers)
    const {success:successRoof,roof} = roofinfo

    const wallinfo = useSelector(state => state.wallReducers)
    const {success:successWall,wall} = wallinfo

    const purokinfo = useSelector(state => state.barangayPurokListReducers)
    const {success:successpurokinfo,purok} = purokinfo

    const floodreports = useSelector(state => state.floodWallRoofReducers)
    const {wallroof,success:successfloodreports} = floodreports

    useEffect(() => {
      if (props.brgyId && successbrgyinfo) { 
        dispatch(roofMaterials())
        dispatch(wallMaterials())
        dispatch(floodWallRoofMaterial(props.brgyId));
        dispatch(puroklistBarangay(props.brgyId))
      }
    }, [dispatch, props.brgyId, successbrgyinfo]); 

    
    useEffect(() => {
      if (successfloodreports) {
        setFloodInfo(wallroof);
      }
    }, [successfloodreports, wallroof]); 
    const barangay_info = Array.isArray(brgyinfoall) && brgyinfoall.find(barangay => barangay.psgccode === props.brgyId)

   

    const tableRef = useRef(null);
    const exportToCSV = () => {
        if (!tableRef.current) return;
        
        const title = [ barangay_info.brgyname + ' Flood Susciptibility ']; 
        const headerRow = Array.from(tableRef.current.querySelectorAll('thead tr th')).map(cell => cell.innerText);
        const rows = Array.from(tableRef.current.querySelectorAll('tbody tr'));
        const data = rows.map(row => Array.from(row.children).map(cell => {
            let cellText = cell.innerText;
            // Wrap in quotes if the text contains a comma or a quote
            if (cellText.includes(',') || cellText.includes(' ')) {
                cellText = `"${cellText.replace(/"/g, ' ')}"`;
            }
            return cellText;
        }));
    
        const csvArray = [title,headerRow, ...data];
    
        // Convert CSV data array to a string with BOM character
        const csv = '\uFEFF' + csvArray.map(row => row.join(',')).join('\n');
    
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'exported_data.csv';
        link.click();
    };

    return (
        <>
             <div className="table-responsive">
                  <span className="txt-color-purple pull-right"> <a href="#" onClick={exportToCSV}><i className="fa fa-download"></i> Export CSV</a></span>
                  <table className="table table-bordered" ref={tableRef}>
                    <thead>
                      <tr>
                        <th>Purok</th>
                        <th>Materials</th>
                        <th>Low</th>
                        <th>Moderate</th>
                        <th>High</th>
                        <th>Very High</th>                       
                      </tr>
                    </thead>
                    <tbody>
                    
                    {Array.isArray(purok) && purok.map((pk, pkIndex) => (
                      pk.purok_name ? (
                        <>
                      <tr key={pkIndex}>
                        <td>{pk.purok_name}</td>
                        <td>ROOF - {roof && roof.find(roof => roof.id === 1 )?.description}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdroofmaterials_id === 1 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdroofmaterials_id === 1 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdroofmaterials_id === 1 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdroofmaterials_id === 1 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>ROOF - {roof && roof.find(roof => roof.id === 2 )?.description}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdroofmaterials_id === 2 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdroofmaterials_id === 2 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdroofmaterials_id === 2 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdroofmaterials_id === 2 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>ROOF - {roof && roof.find(roof => roof.id === 3 )?.description}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdroofmaterials_id === 3 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdroofmaterials_id === 3 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdroofmaterials_id === 3 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdroofmaterials_id === 3 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>ROOF - {roof && roof.find(roof => roof.id === 4 )?.description}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdroofmaterials_id === 4 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdroofmaterials_id === 4 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdroofmaterials_id === 4 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdroofmaterials_id === 4 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>ROOF - {roof && roof.find(roof => roof.id === 5 )?.description}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdroofmaterials_id === 5 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdroofmaterials_id === 5 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdroofmaterials_id === 5 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdroofmaterials_id === 5 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>ROOF - {roof && roof.find(roof => roof.id === 6 )?.description}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdroofmaterials_id === 6 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdroofmaterials_id === 6 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdroofmaterials_id === 6 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdroofmaterials_id === 6 ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>WALL - {wall && wall.find(wall => wall.id === 1 )?.material}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdwallmaterials_id === 1 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdwallmaterials_id === 1 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdwallmaterials_id === 1 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdwallmaterials_id === 1 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>WALL - {wall && wall.find(wall => wall.id === 2 )?.material}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdwallmaterials_id === 2 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdwallmaterials_id === 2 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdwallmaterials_id === 2 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdwallmaterials_id === 2 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>WALL - {wall && wall.find(wall => wall.id === 3 )?.material}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdwallmaterials_id === 3 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdwallmaterials_id === 3 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdwallmaterials_id === 3 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdwallmaterials_id === 3 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>WALL - {wall && wall.find(wall => wall.id === 4 )?.material}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdwallmaterials_id === 4 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdwallmaterials_id === 4 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdwallmaterials_id === 4 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdwallmaterials_id === 4 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>WALL - {wall && wall.find(wall => wall.id === 5 )?.material}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdwallmaterials_id === 5 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdwallmaterials_id === 5 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdwallmaterials_id === 5 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdwallmaterials_id === 5 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>WALL - {wall && wall.find(wall => wall.id === 6 )?.material}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'LF' && flood.householdwallmaterials_id === 6 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'MF' && flood.householdwallmaterials_id === 6 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'HF' && flood.householdwallmaterials_id === 6 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                        <td>{floodInfo ? floodInfo.filter( flood => flood.purok_id === pk.purok_id && flood.flood_id === 'VHF' && flood.householdwallmaterials_id === 6 ).reduce((sum, entry) => sum + (entry.wall_materials_count || 0), 0) : 0}</td>
                      </tr>
                      
                    </>
                      ) : null
                      ))}
                    </tbody>
                  </table>
            </div>
        </>
    )
}
