import React ,{useState,useEffect ,useRef } from 'react'
import { Link , useLocation} from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch,useSelector} from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import SocEcoAffTableFloodMun from '../table/SocEcoAffTableFloodMun' 
import SocEcoAffTableFloodBrgy from '../table/SocEcoAffTableFloodBrgy'
import { USER_UPDATE_PROFILE_RESET } from '../constant/userConstants'

import { getUserDetails,updateUserProfile,getUserLocation } from '../actions/userActions'

import L from 'leaflet';
import 'leaflet.browser.print/dist/leaflet.browser.print'


import { listBarangay } from '../actions/barangayActions'
import { listMunicipal,aggregateMunicipal } from '../actions/municipalActions'
import { listBrgyInfo,listBrgyInfoAll,getFloodReport,getRilReport,getStormSurgeReport,getFloodReportPerMunicipality,getRilReportPerMunicipality,getStormSurgeReportPerMunicipality } from '../actions/reportActions'
import { listPolygonBarangay,adnFlood,adnRil,adnStormSurge,adnMunFlood,adnMunRil,adnMunStormSurge } from '../actions/polygonActions'
import { BARANGAY_POLYGON_RESET,ADN_FLOOD_RESET,ADN_MUN_FLOOD_RESET,ADN_MUN_RIL_RESET,ADN_RIL_RESET,ADN_MUN_STORMSURGE_RESET,ADN_STORMSURGE_RESET } from '../constant/polygonConstants'
import { MUNICIPAL_LIST_RESET} from '../constant/municipalConstants'

import { MapContainer, TileLayer,Tooltip, Marker, GeoJSON , LayersControl, LayerGroup, Popup, useMap  } from 'react-leaflet'
import {Icon,divIcon} from 'leaflet'
import MarkerClusterGroup  from 'react-leaflet-cluster'
import * as htmlToImage from 'html-to-image';

import { getMunicipalityFloodInfo,getFloodInfo} from '../actions/floodActions'
import { getRil,getRilInfo,getMunicipalityRilInfo } from '../actions/rilActions'
import { getStormsurge,getStormsurgeInfo,getMunicipalityStormSurgeInfos } from '../actions/stormsurgeActions'

import barangaymap from '../shapefile/Barangay.json'
import barangaypoint from '../shapefile/Barangay_Point.json'
import adn_flood from '../shapefile/FloodADN.json'
import municipal from '../shapefile/Municipal_ADN.json'

import $ from 'jquery'
import jQuery from 'jquery'
import 'jquery-ui-dist/jquery-ui'
import Select from 'react-select'

import PrintFloodsMunicipalProfile from '../printables/PrintFloodsMunicipalProfile'

import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import CanvasJSReact from '@canvasjs/react-charts';
import { FLOOD_INFO_RESET } from '../constant/floodConstants'
import { RIL_INFO_RESET } from '../constant/rilConstant'
import SocEcoFloodMunPieGraph from '../graph/SocEcoFloodMunPieGraph'
import SocEcoFloodBrgyPieGraph from '../graph/SocEcoFloodBrgyPieGraph'
import SocEcoAffRilBrgyTable from '../table/SocEcoAffRilBrgyTable'
import SocEcoRilBrgyPieGraph from '../graph/SocEcoRilBrgyPieGraph'
import SocEcoRilMunPieGraph from '../graph/SocEcoRilMunPieGraph'
import SocEcoAffRilMunTable from '../table/SocEcoAffRilMunTable'
import AgeDisaggregatedFloodBrgyTable from '../table/AgeDisaggregatedFloodBrgyTable'
import AgeDisaggregratedFloodBrgyGraph from '../graph/AgeDisaggregratedFloodBrgyGraph'
import WallRoofBrgyTable from '../table/WallRoofBrgyTable'
import WallRoofBrgyGraph from '../graph/WallRoofBrgyGraph'
import GenderFloodBrgyTable from '../table/GenderFloodBrgyTable'
import GenderFloodBrgyGraph from '../graph/GenderFloodBrgyGraph'
import AgeDisaggregatedFloodMunTable from '../table/AgeDisaggregatedFloodMunTable'
import AgeDisaggregatedFloodMunGraph from '../graph/AgeDisaggregatedFloodMunGraph'
import axios from 'axios'
import { STORM_SURGE_INFO_RESET } from '../constant/stormsurgeConstant'

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;




const MapWithPrint = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    L.BrowserPrint.Utils.registerLayer(L.MarkerClusterGroup, 'L.MarkerClusterGroup', function (layer, utils) {
      var cluster = L.markerClusterGroup(layer._group.options);
      cluster.addLayers(utils.cloneInnerLayers(layer._group));
      return cluster;
  });

    const printControl = L.control.browserPrint({
      title: 'Print map',
      documentTitle: 'Map Export',
      position: 'topleft',
      printModes: ['Portrait', 'Landscape', 'Auto', 'Custom'],
      manualMode: false,
      closePopupsOnPrint: false,
      printLayer: () => {
        const layers = L.layerGroup(map._layers);
        return layers;
      }
    });

    map.addControl(printControl);

    return () => {
      map.removeControl(printControl);
    };
  }, [map]);

  
  return null;
};


function ProfileScreen({history}) {
  const [adnmunfloods,setAdnMunFloods] = useState(null)

    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [confirmPassword,setConfirmPassword] = useState('')
    const [message,setMessage] = useState('')
    const [psgcmun,setPsgcmun] = useState('')

    const [scriptsgraph,setScriptsgraph] = useState('')


    const [floodMunInfos, setFloodMunInfos] = useState([])
    const [floodInfos, setFloodInfos] = useState([])

    const [rilMunInfos, setRilMunInfos] = useState([])
    const [rilInfos, setRilInfos] = useState([])

    const [stormsurgeMunInfos, setStormsurgeMunInfos] = useState([])
    const [stormsurgeInfos, setStormsurgeInfos] = useState([])
  
    const [brgy, setBrgy] = useState([])
    const [brgypolygons,setBrgyPolygons] = useState(null)
    const [previousEvent, setPreviousEvent] = useState(null);
    const [selectedHazard, setSelectedHazard] = useState('')
    const [selectedValue, setSelectedValue] = useState('')
    const [hazards, setHazards] = useState([])

    const [adnfloods,setAdnFloods] = useState(null)
    const [adnrils,setAdnRils] = useState(null)
    const [adnstormsurges,setAdnStormsurges] = useState(null)

    const dispatch = useDispatch()
    const location = useLocation();
    const { search } =location;
    const [adnmunfld,setAdnMunFld] = useState(null)
    const [adnmunrils,setAdnMunRils] = useState(null)
    const [adnmunstormsurges,setAdnMunStormsurges] = useState(null)


    const [selectedOption, setSelectedOption] = useState(''); // Default selected option
    const [showDiv, setShowDiv] = useState(false); // State to manage the visibility of the div

    const userDetails = useSelector(state => state.userDetails)
    const {error, loading, user} = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo,success:usersuccess} = userLogin

    let user_id = null

    if(userInfo){
      user_id = userInfo.id
    }

    useEffect(()=>{

          dispatch(getUserLocation(user_id))
          dispatch(getUserDetails('profile')) 
    },[user_id])

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const {success} = userUpdateProfile

    const userLocation = useSelector(state => state.userLocation)
    const {userlocation,success:locationsuccess} = userLocation


    const [brgyinformation, setBrgyInformation] = useState([])

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo

    const municipalist = useSelector(state => state.municipalListReducers)
    const {municipals} = municipalist

    const municipalagg = useSelector(state => state.municipalAggReducers)
    const {municipalsagg,success:successmunAgg,loading:loadingMunAgg} = municipalagg

    const barangaylist = useSelector(state => state.barangayListReducers)
    const {barangays} = barangaylist

    const barangaypolygonss = useSelector(state => state.barangayPolygonReducers)
    const {barangaypolygons,success:successbarangaypolygon} = barangaypolygonss

    const adnmunflo = useSelector(state => state.adnMunFloodReducers)
    const {adnmunflood,success:successadnmunflood} = adnmunflo

    const adnmunrilp = useSelector(state => state.adnMunRilReducers)
    const {adnmunril,success:successadnmunrilp} = adnmunrilp

    const adnmunstormsurgep = useSelector(state => state.adnMunStormSurgeReducers)
    const {adnmunstormsurge,success:successadnmunstormsurgep} = adnmunstormsurgep


    const municipalityfloodinform = useSelector(state => state.floodMunInfo)
    const {floodMunucipalityinfo,success:successfloodMunucipalityinfo} = municipalityfloodinform

    const floodinform = useSelector(state => state.floodInfoReducer)
    const {floodinfo,success:successfloodinfo} = floodinform

    const floodReportR = useSelector(state => state.floodReportPerMunicipalityReducer)
    const {floodReportPerMunicipality,success:successfloodReportPerMunicipality} = floodReportR

    const floodreports = useSelector(state => state.floodReportReducer)
    const {floodReport,success:successfloodreports} = floodreports

    const adnflo = useSelector(state => state.adnFloodReducers)
    const {adnflood,success:successadnflood} = adnflo


    const municipalityrilinform = useSelector(state => state.rilMunInfo)
    const {rilMunucipalityinfo,success:successrilMunucipalityinfo} = municipalityrilinform

    const rilReportR = useSelector(state => state.rilReportPerMunicipalityReducer)
    const {rilReportPerMunicipality,success:successrilReportPerMunicipality} = rilReportR

    const rilreports = useSelector(state => state.rilReportReducer)
    const {rilReport,success:successrilreports} = rilreports

    const rilinform = useSelector(state => state.rilInfoReducer)
    const {rilinfo,success:successrilinfo} = rilinform

    const adnrilp = useSelector(state => state.adnRilReducers)
    const {adnril,success:successadnrilp} = adnrilp
    
    const stormsurgeMunInfo = useSelector(state => state.stormSurgeMunicipalityInfosReducer)
    const {stormSurges,success:successstormSurgeMunucipalityinfo} = stormsurgeMunInfo

    const stormsurgeReportR = useSelector(state => state.stormsurgeReportPerMunicipalityReducer)
    const {stormsurgeReportPerMunicipality,success:successstormsurgeReportPerMunicipality} = stormsurgeReportR

    const adnstormsurgep = useSelector(state => state.adnStormSurgeReducers)
    const {adnstormsurge,success:successadnstormsurgep} = adnstormsurgep

    const stormsurgeinform = useSelector(state => state.stormsurgeInfoReducer)
    const {stormsurgeinfo,success:successstormsurgeinfo} = stormsurgeinform

    useEffect(() => {  
        if(!userInfo){
            history.push('/login')
        }else{
            if(!user || !user.name || success){
                dispatch({type:USER_UPDATE_PROFILE_RESET})      
            }else{
                setName(user.name)
                setUsername(user.username)
                setEmail(user.email)
                dispatch(listMunicipal())
                dispatch(listBarangay())
                dispatch(listBrgyInfoAll())
                dispatch(aggregateMunicipal()) 
                
            }
        }
    
    }, [dispatch,history, userInfo, user,success]) 
      
    const submiHandler = (e) => {
        e.preventDefault()

        if(password !== confirmPassword)
        {
            setMessage('Passwords do not match')
        }else{
            dispatch(updateUserProfile({
                'id':user._id,
                'name':name,
                'username':username,
                'email':email,
                'password':password
            }))
            setMessage('')
        }
    }
  
    const [showMap, setShowMap] = useState(true);
    const [showGraph, setShowGraph] = useState(false);
    const [showReport, setShowReports] = useState(false);

    useEffect(()=>{
        if(locationsuccess){setPsgcmun(userlocation.psgccode_mun)    }
        if(successadnmunflood){setAdnMunFld(adnmunflood)}
        if(successadnmunrilp){setAdnMunRils(adnmunril)}
        if(successadnmunstormsurgep){setAdnMunStormsurges(adnmunstormsurge)}
    })

      const stormsurgeMunReport = Array.isArray(stormsurgeReportPerMunicipality) && stormsurgeReportPerMunicipality.filter((feature) => feature.psgccode === psgcmun)

      const numberofbrgy = barangays.filter((item) => item.psgcmun === psgcmun).length;
      const selectedMunicipal = municipals.find((item) => item.psgccode === psgcmun);
      const municipalname = selectedMunicipal ? selectedMunicipal.munname : null;
      const munpsgccode = selectedMunicipal ? selectedMunicipal.psgccode : null;
      const barangay_info = Array.isArray(brgyinfoall) && brgyinfoall.find(barangay => barangay.psgccode === selectedValue)

      const contentRef = useRef(null);

      useEffect(() => {
        if (contentRef.current) {contentRef.current.click();}
      }, []);

      useEffect(()=>{
        if(munpsgccode !== null){
        dispatch(adnMunFlood(munpsgccode))
        dispatch(adnMunRil(munpsgccode))
        dispatch(adnMunStormSurge(munpsgccode))  
        dispatch(getMunicipalityFloodInfo(munpsgccode))
        dispatch(getMunicipalityRilInfo(munpsgccode))
        dispatch(getMunicipalityStormSurgeInfos(munpsgccode))       
        dispatch(getStormSurgeReportPerMunicipality())
        setBrgy(barangays.filter(barangay => barangay.psgcmun === munpsgccode))
        }
  
      },[munpsgccode,barangays,dispatch,barangays])

      
      useEffect(()=>{
        if(successfloodMunucipalityinfo){setFloodMunInfos(floodMunucipalityinfo)}
        if(successfloodinfo){setFloodInfos(floodinfo)}
        if(successrilMunucipalityinfo){setRilMunInfos(rilMunucipalityinfo)}
        if(successrilinfo){setRilInfos(rilinfo)}
        if(successstormSurgeMunucipalityinfo){setStormsurgeMunInfos(stormSurges)}
        if(successstormsurgeinfo){setStormsurgeInfos(stormsurgeinfo)}
      },[successfloodMunucipalityinfo,stormSurges,successstormSurgeMunucipalityinfo,floodMunucipalityinfo,successfloodinfo,floodinfo,successrilinfo,rilinfo,successrilMunucipalityinfo,rilMunucipalityinfo,stormsurgeinfo,successstormsurgeinfo])

     

      const optionsHazard = [

        {label:"FLOOD",value:"1"},
        {label:"RAIN-INDUCED LANDSLIDE",value:"2"},
        {label:"STORM SURGE",value:"3"},
      ]
  

  const mapRef = useRef(null);

  const setColor = ({ properties }) => {
    return { weight: 1 };
  };

  const customMarkerIcon = (name) =>
    divIcon({
      html: name,
      className: "icon"
    });
  
  const setIcon = ({ properties }, latlng) => {
    return L.marker(latlng, { icon: customMarkerIcon(properties.Name) });
  };

  const customIcon = new Icon({
    iconUrl: require("../img/icons8-green-circle-48.png"),
    iconSize: [10,10],
  })

  const customIcon1 = new Icon({
    iconUrl: require("../img/marker-icon.png"),
    iconSize: [20,20],
  })
  
  const optionsBrgy = brgy.sort((a, b) => a.brgyname.localeCompare(b.brgyname)).map((b) => ({
    label: b.brgyname, 
    value: b.psgccode,  
  })); 


  const barangaypolygon = barangaymap.features.filter((feature) => {if(feature.properties.MunPSGC === Number(munpsgccode)){return  feature;}});
  const barangaypoints = barangaypoint.features.filter((feature) => {if(feature.properties.MunPSGC === Number(munpsgccode)){return  feature;}});    
  const [isChecked, setIsChecked] = useState(true); // State to manage checkbox

            // Function to toggle checkbox state
            const handleCheckboxChange = (event) => {
              setIsChecked(prevState => !prevState);  
              setSelectedValue('')
            };
            useEffect(()=>{
             
              if(isChecked)
              {
                dispatch({type:ADN_FLOOD_RESET})
                dispatch({type:ADN_RIL_RESET})
                dispatch({type:ADN_STORMSURGE_RESET})
                dispatch({type:BARANGAY_POLYGON_RESET})
                dispatch({type:FLOOD_INFO_RESET})  
                dispatch({type:RIL_INFO_RESET})   
                dispatch({type:STORM_SURGE_INFO_RESET})   
                setAdnFloods(null);
                setAdnRils(null);
                setAdnStormsurges(null);
                setBrgyPolygons(null);
              }else{
                setSelectedValue('')
                dispatch({type:FLOOD_INFO_RESET}) 
                dispatch({type:RIL_INFO_RESET}) 
                dispatch({type:STORM_SURGE_INFO_RESET}) 
              }

            },[isChecked])
        
            const changeBrgy = (event) => {
              
              if (!event || (previousEvent && event.value === previousEvent.value)) {
                setSelectedValue(previousEvent.value)
                return;
              }else{
                setSelectedValue(event.value)
                dispatch({type:BARANGAY_POLYGON_RESET})   
                dispatch({type:ADN_FLOOD_RESET})
                dispatch({type:ADN_RIL_RESET})
                dispatch({type:ADN_STORMSURGE_RESET})
                setBrgyPolygons(null)
                setAdnFloods(null);
                setAdnRils(null);
                setAdnStormsurges(null);
              }
              setPreviousEvent(event);
            }

            useEffect(() => {
              if(selectedValue){
                  dispatch({type:ADN_FLOOD_RESET})
                  dispatch({type:ADN_RIL_RESET})
                  dispatch(adnFlood(selectedValue))
                  dispatch(adnRil(selectedValue))
                  dispatch(adnStormSurge(selectedValue)) 
                  dispatch(getFloodInfo(selectedValue))            
                  dispatch(getRilInfo(selectedValue))
                  dispatch(getStormsurgeInfo(selectedValue))                 
                  if(successbarangaypolygon){
                    setBrgyPolygons(barangaypolygons)
                    if(successadnflood){setAdnFloods(adnflood)}
                    if(successadnrilp){setAdnRils(adnril)}
                    if(successadnstormsurgep){setAdnStormsurges(adnstormsurge)}  
                  }else{
                    setBrgyPolygons(null)
                    dispatch({type:BARANGAY_POLYGON_RESET})                  
                    dispatch(listPolygonBarangay(selectedValue))
                    setHazards(optionsHazard)
                  }
                     
              }else{
                setSelectedValue('')
              }  

            },[selectedValue,barangaypolygons])

          const handleRadioChange = (event) => {
            setSelectedOption(event.target.value);
            setShowDiv(event.target.value === 'flood'); // Show div only if 'option2' is selected
          };


          const municipalsaggs = municipalsagg.find((feature) => feature.psgccode === psgcmun)
    
          const divRef = useRef(null);
          const handleExport = () => {
            if (!mapRef.current) return;

            // Delay capture to ensure everything is rendered
                setTimeout(() => {
                  html2canvas(divRef.current, {
                    logging: true, // Enable logging for debugging
                    useCORS: true, // Allow capturing cross-origin elements
                  }).then(canvas => {
                    canvas.toBlob(blob => {
                      saveAs(blob, 'exported-image.jpg');
                    }, 'image/jpeg');
                  });
                }, 10); // Adjust delay as needed
              };

          const [isHH, setIsHH] = useState(true);
          const checkboxChange = (event) => {
            setIsHH(prevState => !prevState);  
          };    


  // const [heatindex, setHeatIndex] = useState(true);
  
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const { data } = await axios.get('http://192.168.1.146:8080/data');
  //       setHeatIndex(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   }

  //   const interval = setInterval(() => {
  //     fetchData();
  //   }, 1000); 

  //   return () => clearInterval(interval);
  // }, []);

  const markerClusterGroupRef = useRef(L.markerClusterGroup());

  // useEffect(() => {
  //   if (!floodInfos) return;

  //   // Clear existing markers before adding new ones
  //   markerClusterGroupRef.current.clearLayers();

  //   // Add markers to MarkerClusterGroup
  //   floodInfos.forEach((household) => {
  //     const leafMarker = L.marker([household.lat, household.long], { icon: customIcon });
  //     markerClusterGroupRef.current.addLayer(leafMarker);
  //   });

  
  // }, [floodInfos, customIcon]);

// Register MarkerClusterGroup with leaflet.browser.print

  return (
    <>
   <div id="content" ref={contentRef} onClick={() => console.log('')}>
   <section id="widget-grid" >
  <div className="row">
    <div className="col-sm-12 col-md-12 col-lg-3"> 
      <div className="well well-sm" id="event-container">
        <form>
          <fieldset>
            <legend>
              Socio-Economic Profile of {municipalname} <br/> 
            </legend>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {Array.isArray(municipalsagg) && municipalsagg
                  .filter((agg) => agg.psgccode === psgcmun)
                  .map((filteredmunicipalsagg,index) => (
                    <React.Fragment key={index}>
                    <tr>
                    <td>Household</td>
                    <td>{Number(filteredmunicipalsagg.households).toLocaleString()}</td>
                    </tr>
                    <tr>
                    <td>Families</td>
                    <td>{Number(filteredmunicipalsagg.families).toLocaleString()}</td>
                    </tr>
                    <tr>
                    <td>Population</td>
                    <td>{Number(filteredmunicipalsagg.person).toLocaleString()}</td>
                    </tr>
                    <tr>
                    <td>Person With Disabilities</td>
                    <td>{Number(filteredmunicipalsagg.pwd).toLocaleString()}</td>
                    </tr>
                    <tr>
                    <td>Indigenous People</td>
                    <td>{Number(filteredmunicipalsagg.ip).toLocaleString()}</td>
                    </tr>
                    <tr>
                    <td>Malnourished</td>
                    <td>{Number(filteredmunicipalsagg.malnurished).toLocaleString()}</td>
                    </tr>
                  </React.Fragment>
                    ))}
                    
                </tbody>
              </table>            
            </div>
          </fieldset>
        </form>
      </div>
      <div className="well well-sm" id="event-container">
      <legend>{numberofbrgy} Barangays</legend>
      <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Barangay</th>
                    <th>HH</th>
                    <th>Fam</th>
                    <th>Pop</th>
                  </tr>
                </thead>
                <tbody>
                  
                { Array.isArray(brgyinfoall) && brgyinfoall.filter((municipal)=>municipal.munpsgccode === munpsgccode).sort((a, b) => a.brgyname.localeCompare(b.brgyname)).map((barangay,index) => (
                    
                    <tr key={index}>
                    <td>{barangay.brgyname.toUpperCase()}</td>
                    <td>{barangay.households.toLocaleString()}</td>
                    <td>{barangay.families.toLocaleString()}</td>
                    <td>{barangay.person.toLocaleString()}</td>
                    </tr>
                  
                    ))}
                    
                </tbody>
              </table>          
      </div>
      </div>
    </div>
    <div className="col-sm-12 col-md-12 col-lg-9" id="spark">
    <div className="jarviswidget" id="wid-id-1" data-widget-deletebutton="false" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false">
      <header>
        <h2>Geo Analytics </h2>
        {/* <div class="widget-toolbar" role="menu"> 
          <div class="bg-color-blue text-light">
          {heatindex}
          </div>
        </div> */}
      </header>
      <div>
          <div className="row">
              <div className="col-sm-3">
                  <div className="widget-body no-padding">
                    <form className="smart-form">

                      <fieldset>
                        <section >
                        {loadingMunAgg ? <Loader/>
                                :
                          <label className="toggle">
                            <input type="checkbox" name="checkbox-toggle" checked={isChecked} onChange={handleCheckboxChange}  />
                            <i data-swchon-text="ON" data-swchoff-text="OFF" />Barangay</label>
                        }
                        </section>
                    </fieldset>
                    {isChecked && (
                      
                      <fieldset>
                        <section>
                          <label className="select">
                          <Select key={optionsBrgy} options={optionsBrgy} onChange={changeBrgy}  placeholder="Select Barangay"   className="input-sm"/> <i /> </label>
                        </section>        
                      </fieldset>
                        )}
                      <fieldset>
                        <section>
                          <label className="label">Hazards</label>
                          <div className="inline-group">
                            <label className="radio">
                              <input type="radio" name="radio-inline"  checked={selectedOption === 'flood'}  onChange={handleRadioChange} value="flood"/>
                              <i /><p className="note">Flood</p></label>
                            <label className="radio">
                              <input type="radio" name="radio-inline" checked={selectedOption === 'landslide'}  onChange={handleRadioChange}  value="landslide" />
                              <i /><p className="note">Landslide</p></label>
                            <label className="radio">
                              <input type="radio" name="radio-inline" checked={selectedOption === 'stormsurge'}  onChange={handleRadioChange}  value="stormsurge" />
                              <i /><p className="note">Storm Surge</p></label>
                          </div>
                        </section>
                      </fieldset>
                      <fieldset>
                        <section>
                          <label className="label">Points</label>
                          <div className="inline-group">
                            <label className="checkbox">
                              <input type="checkbox"  name="checkbox" checked={isHH} onChange={checkboxChange}  value="hh"/>
                              <i /><p className="note">Household</p></label>
                           
                          
                          </div>
                        </section>
                      </fieldset>    
                    
                    </form>
                  </div>
                  {/* end widget content */}
                    {selectedOption === 'flood' && ( 
                      <div className="well padding-10">
                          <h5 className="margin-top-0"><i className="fa fa-bullhorn" />  FLOOD</h5>
                          <ul className="no-padding list-unstyled">
                            <li style={{ backgroundColor: '#28286e', color: '#ffffff', textAlign: 'center'  }}>
                              Very High
                            </li>
                            <li style={{ backgroundColor: '#3c46c8', color: '#ffffff', textAlign: 'center'  }}>
                            High
                            </li>
                            <li style={{ backgroundColor: '#783cc8' , color: '#ffffff', textAlign: 'center' }}>
                              Moderate
                            </li>
                            <li style={{ backgroundColor: '#f5dcf5' , color: '#000', textAlign: 'center' }}>
                              Low
                            </li>                 
                          </ul>
                      </div>
                    )}
                    {selectedOption === 'landslide' && ( 
                      <div className="well padding-10">
                          <h5 className="margin-top-0"><i className="fa fa-bullhorn" />  RAIN-INDUCED LANDSLIDE</h5>
                          <ul className="no-padding list-unstyled">
                            <li style={{ backgroundColor: '#963c1e', color: '#ffffff', textAlign: 'center'  }}>
                              Very High
                            </li>
                            <li style={{ backgroundColor: '#f01e1e', color: '#ffffff', textAlign: 'center'  }}>
                            High
                            </li>
                            <li style={{ backgroundColor: '#009600' , color: '#ffffff', textAlign: 'center' }}>
                              Moderate
                            </li>
                            <li style={{ backgroundColor: '#ffff00' , color: '#000', textAlign: 'center' }}>
                              Low
                            </li>                 
                          </ul>
                      </div>
                    )}
                    {selectedOption === 'stormsurge' && ( 
                      <div className="well padding-10">
                          <h5 className="margin-top-0"><i className="fa fa-bullhorn" />  STORM SURGE HEIGHTS</h5>
                          <ul className="no-padding list-unstyled">
                            <li style={{ backgroundColor: '#f01e1e', color: '#ffffff', textAlign: 'center'  }}>
                            Above head surge height
                            </li>
                            <li style={{ backgroundColor: '#ff7f00', color: '#ffffff', textAlign: 'center'  }}>
                            Chest deep surge height
                            </li>
                            <li style={{ backgroundColor: '#ffff00' , color: '#000', textAlign: 'center' }}>
                            Knee deep surge height
                            </li>             
                          </ul>
                      </div>
                    )}
                  
                  {isChecked==false ? (
                    <>
                    {municipalsaggs && (
                      <div className="well padding-10">
                      <div className='row show-grid'>
                        <div className='col-md-12 text-center bg-color-blue txt-color-white'>{municipalsaggs.munname}</div>
                        <div className='col-md-6'>Households</div>
                        <div className='col-md-6'>{Number(municipalsaggs.households).toLocaleString()}</div>
                        <div className='col-md-6'>Families</div>
                        <div className='col-md-6'>{Number(municipalsaggs.families).toLocaleString()}</div>
                        <div className='col-md-6'>Population</div>
                        <div className='col-md-6'>{Number(municipalsaggs.person).toLocaleString()}</div>
                        <div className='col-md-6'>PWD</div>
                        <div className='col-md-6'>{Number(municipalsaggs.pwd).toLocaleString()}</div>
                        <div className='col-md-6'>IP</div>
                        <div className='col-md-6'>{Number(municipalsaggs.ip).toLocaleString()}</div>
                        <div className='col-md-6'>Malnourished</div>
                        <div className='col-md-6'>{Number(municipalsaggs.malnurished).toLocaleString()}</div>
                      </div>
                      </div>
                    )}
                    </>
                  )
                  :
                  <>
                    {barangay_info && (
                      <div className="well padding-10">
                      <div className='row show-grid'>
                        <div className='col-md-12 text-center bg-color-blue txt-color-white'>{barangay_info.brgyname}</div>
                        <div className='col-md-6'>Households</div>
                        <div className='col-md-6'>{Number(barangay_info.households).toLocaleString()}</div>
                        <div className='col-md-6'>Families</div>
                        <div className='col-md-6'>{Number(barangay_info.families).toLocaleString()}</div>
                        <div className='col-md-6'>Population</div>
                        <div className='col-md-6'>{Number(barangay_info.person).toLocaleString()}</div>
                        <div className='col-md-6'>PWD</div>
                        <div className='col-md-6'>{Number(barangay_info.pwd).toLocaleString()}</div>
                        <div className='col-md-6'>IP</div>
                        <div className='col-md-6'>{Number(barangay_info.ip).toLocaleString()}</div>
                        <div className='col-md-6'>Malnourished</div>
                        <div className='col-md-6'>{Number(barangay_info.malnurished).toLocaleString()}</div>
                      </div>
                      </div>
                    )}
                  </>
                  }
              </div>    
              <div className="col-sm-9" >
              {/* <button onClick={handleExport}>Export as JPG</button> */}
                <div className="well padding-10">
                  <div  ref={divRef}>
                <MapContainer  center={[8.947405262757629, 125.54603678275947]} zoom={10} scrollWheelZoom={true}  zoomControl={false} id='mapMuni' ref={mapRef}>
               
                
                
                                {/* <TileLayer  url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
                                <TileLayer  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" maxZoom='20' />

                                {selectedOption === 'flood' && (  

                                          isChecked === false ?  (
                                            
                                            <>
                                              {locationsuccess && ( 
                                              <>
                                                  {adnmunfld && adnmunfld.map((feature, index) => (
                                                      <GeoJSON    
                                                      key={index}                      
                                                        data={feature}
                                                        color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                                                        weight={1}
                                                        opacity={.5}
                                                        fillOpacity={0.8}
                                                        dashArray={3}                             
                                                      >          
                                                        </GeoJSON>
                                                    )) }                                            
                                                </>
                                                )}
                                            </>
                                          )
                                          : 
                                                adnfloods && (adnfloods.map((feature, index) => (
                                                
                                                  <GeoJSON
                                                    key={index} 
                                                    data={feature}
                                                    color={
                                                      feature.properties.Susc_Ratin === "HF" ? "#3c46c8" :
                                                      feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" :
                                                      feature.properties.Susc_Ratin === "MF" ? "#783cc8" :
                                                      "#28286e"
                                                    } 
                                                    weight={2}
                                                    opacity={1}
                                                    fillOpacity={0.8}
                                                    dashArray={3}
                                                  
                                                  />
                                                  
                                                )) )
                                              
                                )}

                                {selectedOption == 'landslide' && (
                                    isChecked === false ?  (
                                          <>
                                              {adnmunrils && adnmunrils.map((feature, index) => (
                                                    
                                                    <GeoJSON    
                                                    key={index}                      
                                                      data={feature}
                                                      color={feature.properties.LndslideSu === "HL" ? "#f01e1e" : feature.properties.LndslideSu === "LL" ? "#ffff00" : feature.properties.LndslideSu === "ML" ? "#009600" : "#963c1e"} 
                                                      weight={1}
                                                      opacity={1}
                                                      fillOpacity={0.8}
                                                      dashArray={3}
                                                                                
                                                    >          
                                                      </GeoJSON>
                                                  ))}
                                          </>
                                            )
                                  :     
                                        adnrils && (adnrils.map((feature, index) => (   
                                          <GeoJSON
                                          key={index} 
                                            data={feature}
                                            color={feature.properties.LndslideSu === "HL" ? "#f01e1e" : feature.properties.LndslideSu === "LL" ? "#ffff00" : feature.properties.LndslideSu === "ML" ? "#009600" : "#963c1e"} 
                                            weight={2}
                                            opacity={1}
                                            fillOpacity={0.8}
                                            dashArray={3}
                                          />)))     
                                  )}

                                 {selectedOption === 'stormsurge' && (
                                      isChecked === false ?  (
                                      <>
                                          {adnmunstormsurges && adnmunstormsurges.map((feature, index) => (
                                            
                                                <GeoJSON       
                                                key={index}                   
                                                  data={feature}
                                                  color={feature.properties.HAZ === 1 ? "#ffff00" : feature.properties.HAZ === 2 ? "#ff7f00" : "#f01e1e" } 
                                                  weight={1}
                                                  opacity={1}
                                                  fillOpacity={0.8}
                                                  dashArray={3}                        
                                                >          
                                                  </GeoJSON>
                                          
                                            ))}
                                      </>
                                      )
                                 :
                                      adnstormsurges && (adnstormsurges.map((feature, index) => (
                                       
                                        <GeoJSON
                                          key={index} 
                                          data={feature}
                                          color={feature.properties.HAZ === 1 ? "#ffff00" : feature.properties.HAZ === 2 ? "#ff7f00" : "#f01e1e" } 
                                          weight={2}
                                          opacity={1}
                                          fillOpacity={0.8}
                                          dashArray={3}
                                        />
                                       
                                        )))
                                  )}

                                    {isChecked === false ? (
                                      <>
                                        {isHH && (
                                          <>
                                            {selectedOption === 'flood' && (
                                              <MarkerClusterGroup
                                                spiderfyOnMaxZoom={true}
                                                showCoverageOnHover={false}
                                                maxClusterRadius={35}
                                                disableClusteringAtZoom={18}
                                              >
                                                {Array.isArray(floodMunInfos) && floodMunInfos.map((household, index) => (
                                                  <Marker key={index} position={[household.lat, household.long]} icon={customIcon} />
                                                ))}
                                              </MarkerClusterGroup>
                                            )}
                                            {selectedOption === 'landslide' && (
                                              <MarkerClusterGroup
                                                spiderfyOnMaxZoom={true}
                                                showCoverageOnHover={false}
                                                maxClusterRadius={35}
                                                disableClusteringAtZoom={18}
                                              >
                                                {rilMunInfos && rilMunInfos.map((household, index) => (
                                                  <Marker key={index} position={[household.lat, household.long]} icon={customIcon} />
                                                ))}
                                              
                                              </MarkerClusterGroup>
                                            )}

                                            {selectedOption === 'stormsurge' && (
                                              <MarkerClusterGroup
                                                spiderfyOnMaxZoom={true}
                                                showCoverageOnHover={false}
                                                maxClusterRadius={35}
                                                disableClusteringAtZoom={18}
                                              >
                                                {stormsurgeMunInfos && stormsurgeMunInfos.map((household, index) => (
                                                  <Marker key={index} position={[household.lat, household.long]} icon={customIcon} />
                                                ))}
                                              
                                              </MarkerClusterGroup>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {isHH && (
                                          <>
                                            {selectedOption === 'flood' && (
                                              <MarkerClusterGroup
                                            
                                                spiderfyOnMaxZoom={true}
                                                showCoverageOnHover={false}
                                                maxClusterRadius={35}
                                                disableClusteringAtZoom={18}
                                              >
                                                {floodInfos && floodInfos.map((household, index) => (
                                                  <Marker key={index} position={[household.lat, household.long]} icon={customIcon} />
                                                ))}
                                              </MarkerClusterGroup>
                                            )}
                                            {selectedOption === 'landslide' && (
                                              <MarkerClusterGroup
                                                spiderfyOnMaxZoom={true}
                                                showCoverageOnHover={false}
                                                maxClusterRadius={35}
                                                disableClusteringAtZoom={18}
                                              >
                                                {rilInfos && rilInfos.map((household, index) => (
                                                  <Marker key={index} position={[household.lat, household.long]} icon={customIcon} />
                                                ))}
                                              </MarkerClusterGroup>
                                            )}
                                            {selectedOption === 'stormsurge' && (
                                              <MarkerClusterGroup
                                                spiderfyOnMaxZoom={true}
                                                showCoverageOnHover={false}
                                                maxClusterRadius={35}
                                                disableClusteringAtZoom={18}
                                              >
                                                {stormsurgeInfos && stormsurgeInfos.map((household, index) => (
                                                  <Marker key={index} position={[household.lat, household.long]} icon={customIcon} />
                                                ))}
                                              </MarkerClusterGroup>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}

                                  <LayersControl.Overlay name="Barangay Boundary"  >

                                      <GeoJSON data={barangaymap} style={setColor} key={barangaymap.BrgyPSGC} pointToLayer={setIcon} zIndex={0} />  

                                  </LayersControl.Overlay>

                                  {barangaypolygon && barangaypolygon.map((feature, index) => (
                                    (<GeoJSON data={feature} 
                                        key={index}
                                        color="white" 
                                        weight={1}
                                        opacity={1}
                                        dashArray={10}
                                        fillOpacity={0}
                                        > 
                                    </GeoJSON>
                                    )
                                  ))}

                                  {barangaypoints && barangaypoints.map((feature, index) => (
          
                                        
                                            <Marker   
                                            key={index}
                                            position={[feature.geometry.coordinates[1],feature.geometry.coordinates[0]]} 
                                            icon={customIcon1}
                                            > 
                                            <Popup>{feature.properties.BarangayNa}</Popup>
                                            {/* <Tooltip permanent>{feature.properties.BarangayNa}</Tooltip> */}
                                        </Marker>
                                  ))}

                                  { isChecked && brgypolygons &&
                                  (<GeoJSON data={brgypolygons} 
                                      color="black" 
                                      weight={3}
                                      opacity={1}
                                      dashArray={10}
                                      fillOpacity={0}
                                      > 
                                  </GeoJSON>
                                  
                                  )
                                }
                
                <MapWithPrint />
                </MapContainer>	
                </div>
                </div>
              </div>         
          </div>
          {isChecked === false ? (
            <>
            {selectedOption === 'flood' && (
                <>
                  <SocEcoFloodMunPieGraph municipalId={psgcmun} />
                  <SocEcoAffTableFloodMun municipalId={psgcmun} />     
                  <br/>
                  <br/>
                  <br/>        
                  <AgeDisaggregatedFloodMunGraph municipalId={psgcmun} />    
                  <AgeDisaggregatedFloodMunTable municipalId={psgcmun} />      
                </>      
            )}
            {selectedOption === 'landslide' && (
                <>
                  <SocEcoRilMunPieGraph municipalId={psgcmun} />
                  <SocEcoAffRilMunTable municipalId={psgcmun} />                
                </>      
            )}
            </>
          )
          :
          <>
          {selectedOption === 'flood' && (
            selectedValue && (
              <>
                <SocEcoFloodBrgyPieGraph brgyId={selectedValue} />
                <SocEcoAffTableFloodBrgy brgyId={selectedValue} />
                <br/>
                <br/>
                <br/>
                <AgeDisaggregratedFloodBrgyGraph brgyId={selectedValue}/>
                <AgeDisaggregatedFloodBrgyTable brgyId={selectedValue} />
                <br/>
                <br/>
                <br/>
                <GenderFloodBrgyGraph brgyId={selectedValue}/>
                <GenderFloodBrgyTable  brgyId={selectedValue}/>
                <br/>
                <br/>
                <br/>
                <WallRoofBrgyGraph brgyId={selectedValue} />
                <WallRoofBrgyTable brgyId={selectedValue}/>
              </>
            )
          )}

          {selectedOption === 'landslide' && (
            selectedValue && (
              <>
                <SocEcoRilBrgyPieGraph brgyId={selectedValue} />
                <SocEcoAffRilBrgyTable brgyId={selectedValue} />
              </>
            )
          )}
          </>
          }
      </div>
    </div>            
    </div>
      
  </div>
  {/* end row */}
  </section>
</div>
    </>

  )
}

export default ProfileScreen
