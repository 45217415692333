import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'

import {getPurokFloodInfo} from '../actions/floodActions'
import {puroklistBarangay} from '../actions/barangayActions'




export default function AgeDisaggregatedFloodBrgyTable(props) {
    const dispatch = useDispatch()
    const [floodInfo,setFloodInfo] = useState(null)

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo

    const purokinfo = useSelector(state => state.barangayPurokListReducers)
    const {success:successpurokinfo,purok} = purokinfo

    const floodreports = useSelector(state => state.floodPurokInfo)
    const {floodpurokinfo,success:successfloodreports} = floodreports

    useEffect(() => {
      if (props.brgyId && successbrgyinfo) { 
        dispatch(getPurokFloodInfo(props.brgyId));
        dispatch(puroklistBarangay(props.brgyId))
      }
    }, [dispatch, props.brgyId, successbrgyinfo]); 
  
    useEffect(() => {
      if (successfloodreports) {
        setFloodInfo(floodpurokinfo);
      }
    }, [successfloodreports, floodpurokinfo]); 
                
    const barangay_info = Array.isArray(brgyinfoall) && brgyinfoall.find(barangay => barangay.psgccode === props.brgyId)

    const tableRef = useRef(null);
    const exportToCSV = () => {
        if (!tableRef.current) return;
        
        const title = [barangay_info.brgyname +  ' Flood Susciptibility ']; 
        const headerRow = Array.from(tableRef.current.querySelectorAll('thead tr th')).map(cell => cell.innerText);
        const rows = Array.from(tableRef.current.querySelectorAll('tbody tr'));
        const data = rows.map(row => Array.from(row.children).map(cell => {
            let cellText = cell.innerText;
            // Wrap in quotes if the text contains a comma or a quote
            if (cellText.includes(',') || cellText.includes(' ')) {
                cellText = `"${cellText.replace(/"/g, ' ')}"`;
            }
            return cellText;
        }));
    
        const csvArray = [title,headerRow, ...data];
    
        // Convert CSV data array to a string with BOM character
        const csv = '\uFEFF' + csvArray.map(row => row.join(',')).join('\n');
    
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'exported_data.csv';
        link.click();
    };


   
   
    return (
        <>
            <div className="table-responsive">
                  <span className="txt-color-purple pull-right"> <a href="#" onClick={exportToCSV}><i className="fa fa-download"></i> Export CSV</a></span>
                  <table className="table table-bordered" ref={tableRef}>
                    <thead>
                      <tr>
                        <th>Purok</th>
                        <th>Age</th>
                        <th>Low</th>
                        <th>Moderate</th>
                        <th>High</th>
                        <th>Very High</th>                       
                      </tr>
                    </thead>
                    <tbody>
                    
                {Array.isArray(purok) && purok.map((pk, index) => (
                    pk.purok_name ? (
                        <>
                        <tr key={index}>
                            <td>{pk.purok_name}</td>
                            <td>Elderly</td>
                            <td>
                                {                

                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'LF')?.elderly_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'LF')?.elderly_female || 0)
                                    ) || ''
                                    
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'MF')?.elderly_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'MF')?.elderly_female || 0)
                                    ) || ''
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'HF')?.elderly_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'HF')?.elderly_female || 0)
                                    ) || ''
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'VHF')?.elderly_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'VHF')?.elderly_female || 0)
                                    ) || ''
                                }
                            </td>
                        </tr>
                        <tr>
                        <td></td>
                        <td>Adult</td>
                        <td>
                                {                

                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'LF')?.adult_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'LF')?.adult_female || 0)
                                    ) || ''
                                    
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'MF')?.adult_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'MF')?.adult_female || 0)
                                    ) || ''
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'HF')?.adult_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'HF')?.adult_female || 0)
                                    ) || ''
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'VHF')?.adult_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'VHF')?.adult_female || 0)
                                    ) || ''
                                }
                            </td>

                        </tr>
                        <tr>
                        <td></td>
                        <td>Children</td>
                        <td>
                                {                

                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'LF')?.children_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'LF')?.children_female || 0)
                                    ) || ''
                                    
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'MF')?.children_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'MF')?.children_female || 0)
                                    ) || ''
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'HF')?.children_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'HF')?.children_female || 0)
                                    ) || ''
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'VHF')?.children_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'VHF')?.children_female || 0)
                                    ) || ''
                                }
                            </td>
                        </tr>
                        <tr>
                        <td></td>
                        <td>Infant</td>
                        <td>
                                {                

                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'LF')?.infant_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'LF')?.infant_female || 0)
                                    ) || ''
                                    
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'MF')?.infant_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'MF')?.infant_female || 0)
                                    ) || ''
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'HF')?.infant_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'HF')?.infant_female || 0)
                                    ) || ''
                                }
                            </td>
                            <td>
                                {
                                    (
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'VHF')?.infant_male || 0) + 
                                        (floodInfo && floodInfo.find(flood => flood.purok_name === pk.purok_name && flood.flood_id === 'VHF')?.infant_female || 0)
                                    ) || ''
                                }
                            </td>
                        </tr>
                        </>
                    ) : null
                ))}

                        
                    </tbody>
                  </table>
            </div>
            
        </>
    )
}
