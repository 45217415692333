import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'
import {getRilReport} from '../actions/reportActions'

export default function SocEcoAffRilBrgyTable(props) {
    const dispatch = useDispatch()
    const [rilInfo,setRilInfo] = useState(null)

    const rilreports = useSelector(state => state.rilReportReducer)
    const {rilReport,success:successrilreports} = rilreports
    

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo


    useEffect(()=> {
        if(props.brgyId && successbrgyinfo){
          dispatch(getRilReport(props.brgyId))
        }
        
       },[props.brgyId,successbrgyinfo,dispatch])

       useEffect(()=> {

        if(successrilreports){
          setRilInfo(rilReport)
       }
       },[successrilreports,rilReport])

    const barangay_info = Array.isArray(brgyinfoall) && brgyinfoall.find(barangay => barangay.psgccode === props.brgyId)
    const tableRef = useRef(null);

    const exportToCSV = () => {
      if (!tableRef.current) return;
      
      const title = [ barangay_info.brgyname +  ' Flood Susciptibility ']; 
      const headerRow = Array.from(tableRef.current.querySelectorAll('thead tr th')).map(cell => cell.innerText);
      const rows = Array.from(tableRef.current.querySelectorAll('tbody tr'));
      const data = rows.map(row => Array.from(row.children).map(cell => {
          let cellText = cell.innerText;
          // Wrap in quotes if the text contains a comma or a quote
          if (cellText.includes(',') || cellText.includes(' ')) {
              cellText = `"${cellText.replace(/"/g, ' ')}"`;
          }
          return cellText;
      }));
  
      const csvArray = [title,headerRow, ...data];
  
      // Convert CSV data array to a string with BOM character
      const csv = '\uFEFF' + csvArray.map(row => row.join(',')).join('\n');
  
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'exported_data.csv';
      link.click();
  };
    return (
  
            <div className="table-responsive">
                  <span className="txt-color-purple pull-right"> <a href="#" onClick={exportToCSV}><i className="fa fa-download"></i> Export CSV</a></span>
                  <table className="table table-bordered" ref={tableRef}>
                    <thead>
                      <tr>
                        <th>Classification</th>
                        <th>Low</th>
                        <th>Moderate</th>
                        <th>High</th>
                        <th>Very High</th>
                        <th>Safe</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr>
                        <td>Population</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.person).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.person).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.person).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.person).toLocaleString() : null})}</td>
                        <td>{Number(barangay_info.person - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.person || 0), 0) : 0)).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.person).toLocaleString() : null}</td>
                       </tr>
                       <tr>
                        <td>Households</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.household_number).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.household_number).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.household_number).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.household_number).toLocaleString() : null})}</td>
                        <td>{Number(barangay_info.households - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.household_number || 0), 0) : 0)).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.households).toLocaleString() : null}</td>
                       </tr>
                       <tr>
                        <td>Families</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.families).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.families).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.families).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.families).toLocaleString() : null})}</td>
                        <td>{Number(barangay_info.families - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.families || 0), 0) : 0)).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.families).toLocaleString() : null}</td>
                       </tr>
                       <tr>
                        <td>Male</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.male).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.male).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.male).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.male).toLocaleString() : null})}</td>
                        <td>{Number(barangay_info.male - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.male || 0), 0) : 0)).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.male).toLocaleString() : null}</td>
                       </tr>
                       <tr>
                        <td>Female</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.female).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.female).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.female).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.female).toLocaleString() : null})}</td>
                        <td>{Number(barangay_info.female - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.female || 0), 0) : 0)).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.female).toLocaleString() : null}</td>
                       </tr>                      
                       <tr>
                        <td>Elderly</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.elderly_male + ril.elderly_female).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.elderly_male + ril.elderly_female).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.elderly_male + ril.elderly_female).toLocaleString(): null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.elderly_male + ril.elderly_female).toLocaleString() : null})}</td>
                        <td>{Number((barangay_info.elderly_male + barangay_info.elderly_female) - ((Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.elderly_male || 0), 0)  : 0) + (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.elderly_female || 0), 0)  : 0))).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.elderly_male + barangay_info.elderly_female).toLocaleString() : null}</td>
                       </tr>
                       <tr>
                          <td>Children</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.children_male + ril.children_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.children_male + ril.children_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.children_male + ril.children_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.children_male + ril.children_female).toLocaleString() : null})}</td>
                          <td>{Number((barangay_info.children) - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.children_male || entry.children_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{barangay_info ? Number(barangay_info.children).toLocaleString(): null}</td>
                        </tr>
                        <tr>
                          <td>Adult</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.adult_male + ril.adult_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.adult_male + ril.adult_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.adult_male + ril.adult_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.adult_male + ril.adult_female).toLocaleString() : null})}</td>
                          <td>{Number((barangay_info.adult) - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.adult_male || entry.adult_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{barangay_info ? Number(barangay_info.adult).toLocaleString(): null}</td>
                        </tr>    
                        <tr>
                          <td>Infant</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.infant_male + ril.infant_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.infant_male + ril.infant_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.infant_male + ril.infant_female).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.infant_male + ril.infant_female).toLocaleString() : null})}</td>
                          <td>{Number((barangay_info.infant) - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.infant_male || entry.infant_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{barangay_info ? Number(barangay_info.infant).toLocaleString(): null}</td>
                        </tr>
                        <tr>
                        <td>Person With Disabilities</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.pwd).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.pwd).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.pwd).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.pwd).toLocaleString() : null})}</td>
                        <td>{Number(barangay_info.pwd - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.pwd || 0), 0) : 0)).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.pwd).toLocaleString() : null}</td>
                       </tr>
                       <tr>
                        <td>Indigenous People</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.ip).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.ip).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.ip).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.ip).toLocaleString() : null})}</td>
                        <td>{Number(barangay_info.ip - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.ip || 0), 0) : 0)).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.ip).toLocaleString() : null}</td>
                       </tr>
                       <tr>
                        <td>Malnourished</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.malnurished).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.malnurished).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.malnurished).toLocaleString() : null})}</td>
                        <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL" ? Number(ril.malnurished).toLocaleString() : null})}</td>
                        <td>{Number(barangay_info.ip - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.malnurished || 0), 0) : 0)).toLocaleString()}</td>
                        <td>{barangay_info ? Number(barangay_info.malnurished).toLocaleString() : null}</td>
                       </tr>                       
                        <tr>
                          <td>HH With Illness</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hh_with_illness - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hh_with_illness || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hh_with_illness).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Person With Philhealth</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.philhealth).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.philhealth).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.philhealth).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.philhealth).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.philhealth - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.philhealth || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.philhealth).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Person With Financial Assistance</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.sss_gsis).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.sss_gsis).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.sss_gsis).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.sss_gsis).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.sss_gsis - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.sss_gsis || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.sss_gsis).toLocaleString() : null}</td>
                        </tr>                      
                        <tr>
                          <td>HH With Paticipation in any Drill</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hh_access_drill - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hh_access_drill || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hh_access_drill).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Student</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hd_attending_school - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hd_attending_school || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hd_attending_school).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Potable Water</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.potable_water).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.potable_water).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.potable_water).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.potable_water).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.potable_water - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.potable_water || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.potable_water).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Electricity</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hh_access_electricity - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hh_access_electricity || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hh_access_electricity).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Internet</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hh_access_internet - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hh_access_internet || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hh_access_internet).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Construct Before 1972</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_1972).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_1972).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_1972).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_1972).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hh_1972 - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hh_1972 || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hh_1972).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Construct After 1972</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hh_1972_onwards - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hh_1972_onwards || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hh_1972_onwards).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Susceptibility</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_susc_ril).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_susc_ril).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_susc_ril).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_susc_ril).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hh_house - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hh_susc_ril || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hh_house).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Susceptibility Estimated Cost</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_susc_ril_cost).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_susc_ril_cost).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_susc_ril_cost).toLocaleString() : null})}</td>
                          <td>{rilInfo && rilInfo.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_susc_ril_cost).toLocaleString() : null})}</td>
                          <td>{Number(barangay_info.hh_house_cost - (Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.hh_house_cost || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ barangay_info ?  Number(barangay_info.hh_house_cost).toLocaleString() : null}</td>
                        </tr>
                    </tbody>
                  </table>
            </div>
   
    )
}
